var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      staticClass: "profile-user-add",
      attrs: { name: _vm.name, width: "500px" },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_c("div", [_vm._v(_vm._s(_vm.getTitle))])]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "formAddUser",
          staticClass: "profile-user-add__form",
          attrs: {
            model: _vm.formAddUser,
            rules: _vm.rules,
            "label-width": "230px",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Имя", prop: "name" } },
            [
              _c("el-input", {
                class: { "w-175": !_vm.isMobile, "w-100p": _vm.isMobile },
                attrs: { placeholder: "", size: "small" },
                model: {
                  value: _vm.formAddUser.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddUser, "name", $$v)
                  },
                  expression: "formAddUser.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Фамилия", prop: "surname" } },
            [
              _c("el-input", {
                class: { "w-175": !_vm.isMobile, "w-100p": _vm.isMobile },
                attrs: { placeholder: "", size: "small" },
                model: {
                  value: _vm.formAddUser.surname,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddUser, "surname", $$v)
                  },
                  expression: "formAddUser.surname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Должность (роль в системе)", prop: "role" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "w-175",
                  attrs: { placeholder: "", size: "small" },
                  model: {
                    value: _vm.formAddUser.role,
                    callback: function ($$v) {
                      _vm.$set(_vm.formAddUser, "role", $$v)
                    },
                    expression: "formAddUser.role",
                  },
                },
                _vm._l(_vm.usersRolesProfile, function (role) {
                  return _c("el-option", {
                    key: role.id,
                    attrs: { label: role.title, value: role.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Email", prop: "email" } },
            [
              _c("el-input", {
                class: { "w-175": !_vm.isMobile, "w-100p": _vm.isMobile },
                attrs: { placeholder: "", size: "small" },
                model: {
                  value: _vm.formAddUser.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddUser, "email", $$v)
                  },
                  expression: "formAddUser.email",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Телефон", prop: "phone" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "+7 ### ###-##-##",
                    expression: "'+7 ### ###-##-##'",
                  },
                ],
                class: { "w-175": !_vm.isMobile, "w-100p": _vm.isMobile },
                attrs: { placeholder: "", size: "small" },
                model: {
                  value: _vm.formAddUser.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddUser, "phone", $$v)
                  },
                  expression: "formAddUser.phone",
                },
              }),
            ],
            1
          ),
          _vm.isEdit
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.getPasswordTitle, prop: "password" } },
                [
                  _c("el-input", {
                    class: { "w-175": !_vm.isMobile, "w-100p": _vm.isMobile },
                    attrs: {
                      placeholder: "",
                      size: "small",
                      "show-password": "",
                    },
                    model: {
                      value: _vm.formAddUser.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.formAddUser, "password", $$v)
                      },
                      expression: "formAddUser.password",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.isEdit
            ? _c("div", { staticClass: "profile-user-add__note" }, [
                _vm._v(
                  " Телефонный номер будет использован как логин в системе, пароль для входа будет выслан на указанный номер. "
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "profile-user-add__bottom" }, [
            _vm.isEdit
              ? _c(
                  "div",
                  { staticClass: "profile-user-add__left" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "profile-user-add__btn",
                        attrs: { disabled: _vm.isLoading },
                        on: { click: _vm.handleDeleteUser },
                      },
                      [_vm._v(" Удалить пользователя ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "profile-user-add__right" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "profile-user-add__btn",
                    attrs: { type: "warning", loading: _vm.isLoading },
                    on: { click: _vm.handleUpdateUser },
                  },
                  [_vm._v(" " + _vm._s(_vm.getBtnTitle) + " ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }