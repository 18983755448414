<template>
  <Dialog :name="name" width="500px" class="profile-user-add">
    <template #title>
      <div>{{ getTitle }}</div>
    </template>

    <el-form
      ref="formAddUser"
      :model="formAddUser"
      :rules="rules"
      label-width="230px"
      class="profile-user-add__form"
      @submit.native.prevent
    >
      <el-form-item label="Имя" prop="name">
        <el-input
          v-model="formAddUser.name"
          placeholder=""
          size="small"
          :class="{ 'w-175': !isMobile, 'w-100p': isMobile }"
        />
      </el-form-item>

      <el-form-item label="Фамилия" prop="surname">
        <el-input
          v-model="formAddUser.surname"
          placeholder=""
          size="small"
          :class="{ 'w-175': !isMobile, 'w-100p': isMobile }"
        />
      </el-form-item>

      <el-form-item label="Должность (роль в системе)" prop="role">
        <el-select
          v-model="formAddUser.role"
          placeholder=""
          size="small"
          class="w-175"
        >
          <el-option
            v-for="role in usersRolesProfile"
            :key="role.id"
            :label="role.title"
            :value="role.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Email" prop="email">
        <el-input
          v-model="formAddUser.email"
          placeholder=""
          size="small"
          :class="{ 'w-175': !isMobile, 'w-100p': isMobile }"
        />
      </el-form-item>

      <el-form-item label="Телефон" prop="phone">
        <el-input
          v-model="formAddUser.phone"
          v-mask="'+7 ### ###-##-##'"
          placeholder=""
          size="small"
          :class="{ 'w-175': !isMobile, 'w-100p': isMobile }"
        />
      </el-form-item>

      <el-form-item v-if="isEdit" :label="getPasswordTitle" prop="password">
        <el-input
          v-model="formAddUser.password"
          placeholder=""
          size="small"
          :class="{ 'w-175': !isMobile, 'w-100p': isMobile }"
          show-password
        />
      </el-form-item>

      <div v-if="!isEdit" class="profile-user-add__note">
        Телефонный номер будет использован как логин в системе, пароль для входа
        будет выслан на указанный номер.
      </div>

      <div class="profile-user-add__bottom">
        <div v-if="isEdit" class="profile-user-add__left">
          <el-button
            class="profile-user-add__btn"
            :disabled="isLoading"
            @click="handleDeleteUser"
          >
            Удалить пользователя
          </el-button>
        </div>
        <div class="profile-user-add__right">
          <el-button
            type="warning"
            class="profile-user-add__btn"
            :loading="isLoading"
            @click="handleUpdateUser"
          >
            {{ getBtnTitle }}
          </el-button>
        </div>
      </div>
    </el-form>
  </Dialog>
</template>

<script>
import {
  DELETE_USERS,
  GET_IS_LOADING_USERS,
  POST_USER,
  UPDATE_PROFILE,
} from '@/views/profile/store/actions'
import { PROFILE_NEW_USER } from '@/constants/dialogs'
import { formAddUserRules } from '@/views/profile/components/profile-add-user/data/profileAddUser'
import { mapActions, mapGetters } from 'vuex'
import { usersRolesProfile } from '@/constants/roles'
import Dialog from '@/UI/dialog/Dialog'

export default {
  name: 'ProfileAddUser',
  components: { Dialog },
  data() {
    return {
      name: PROFILE_NEW_USER,
      formAddUser: {
        name: '',
        surname: '',
        role: '',
        email: '',
        phone: '',
        password: '',
      },
      rules: formAddUserRules,
      usersRolesProfile,
    }
  },
  computed: {
    ...mapGetters({
      isLoading: GET_IS_LOADING_USERS,
    }),
    dialogData() {
      return this.getDialog(this.name)?.data || {}
    },
    isEdit() {
      return this.dialogData?.isEdit
    },
    getTitle() {
      return this.isEdit
        ? 'Изменить профиль пользователя'
        : 'Добавить нового пользователя'
    },
    getPasswordTitle() {
      return this.isEdit ? 'Изменить пароль' : 'Пароль'
    },
    getBtnTitle() {
      return this.isEdit ? 'Сохранить изменеия' : 'Добавить пользователя'
    },
  },
  watch: {
    dialogData(val) {
      if (val.isEdit) {
        this.setFormFields(
          val.first_name || '',
          val.last_name || '',
          val.role || null,
          val.email || '',
          val.phone || '',
        )
      } else {
        this.setFormFields()
      }
    },
  },
  methods: {
    ...mapActions({
      deleteUser: DELETE_USERS,
      updateUser: UPDATE_PROFILE,
      postUser: POST_USER,
    }),
    handleDeleteUser() {
      const deleteRequestData = {
        id: this.dialogData.id,
        nameDialog: this.name,
        setFormFields: this.setFormFields,
      }

      this.deleteUser(deleteRequestData)
    },
    handleUpdateUser() {
      this.$refs.formAddUser.validate(valid => {
        if (valid) {
          const requestData = {
            name: this.formAddUser.name,
            surname: this.formAddUser.surname,
            role: this.formAddUser.role,
            email: this.formAddUser.email,
            phone: this.formAddUser.phone,
            password: this.formAddUser.password,
            id: this.dialogData.id || null,
            isOrdinaryUser: false,
            nameDialog: this.name,
            setFormFields: this.setFormFields,
          }

          if (this.isEdit) {
            this.updateUser(requestData)
          } else {
            this.postUser(requestData)
          }
        }
      })
    },
    setFormFields(
      name = '',
      surname = '',
      role = '',
      email = '',
      phone = '',
      password = '',
    ) {
      this.formAddUser.name = name
      this.formAddUser.surname = surname
      this.formAddUser.role = role
      this.formAddUser.email = email
      this.formAddUser.phone = phone
      this.formAddUser.password = password
    },
  },
}
</script>

<style lang="sass">
.profile-user-add
  &__bottom
    display: flex
    justify-content: center
    align-items: center
    margin-top: 50px

  &__left,
  &__right
    flex: 0 0 50%
    display: flex
    justify-content: center
    align-items: center

  &__btn
    width: 200px

  &__note
    font-size: 10px
    text-align: right
    padding-left: 120px
</style>
