export const formAddUserRules = {
  name: [
    {
      required: true,
      message: 'Заполните имя',
      trigger: 'blur',
    },
  ],
  surname: [
    {
      required: true,
      message: 'Заполните Фамилия',
      trigger: 'blur',
    },
  ],
  role: [
    {
      required: true,
      message: 'Выберите роль',
      trigger: 'blur',
    },
  ],
  email: [
    {
      required: true,
      message: 'Заполните email',
      trigger: 'blur',
    },
    {
      type: 'email',
      required: true,
      message: 'Формат поля email@email.com',
      trigger: 'blur',
    },
  ],
  phone: [
    {
      required: true,
      message: 'Заполните телефон',
      trigger: 'blur',
    },
  ],
  password: [
    {
      min: 4,
      max: 18,
      message: 'Пароль слишком короткий',
      trigger: 'blur',
    },
  ],
}
